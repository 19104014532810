import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/Seo/Seo";
import AccommodationsModules from "../components/ModuleAccommodations/AccommodationsModules";
import Banner from "../components/ModuleAccommodations/Banner";
import AccommodationFutures from "../components/ModuleAccommodations/AccommodationFutures";

const PageTemplate = (props) => {

  useEffect(() => {
    document.body.classList.remove("mega-menu-open");
    document.body.classList.remove("booking-open");
    document.body.classList.remove("form-enquiry-open");
    document.body.classList.remove("menu-open");
  });

  const post = props.data.post;
  const postData = {
    image: props.data.post.featuredImage.node,
    title: props.data.post.title,
    id: props.data.post.id,
    excerpt: props.data.post.excerpt,
    bannerVideo : props.data.post.roomsContent.accommodationBannerVideo
  };
  return (
    <Layout>
      <Seo seo={post.seo}/>
      <Banner postData={postData} />
      <AccommodationFutures />
      <AccommodationsModules postData={postData} modules={post.roomsContent} />
    </Layout>
  );
};

export default PageTemplate;
export const pageQuery = graphql/* GraphQL */ `
  query PostById($id: String!) {
    post: wpAccommodation(id: { eq: $id }) {
      id
      title
      featuredImage {
        node {
          altText
          gatsbyImage(
            width: 1000
            formats: WEBP
            layout: FULL_WIDTH
            placeholder: BLURRED
          )
        }
      }
      seo {
        canonical
        fullHead
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        title
        twitterDescription
        twitterTitle
      }
      ...AccommodationsModulesFragment
    }
  }
`;
